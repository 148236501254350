<template>
  <div>
    <div v-for='(term, index) in terms'
      :key='`term-${index}`'
      class='text-xs border rounded-md mb-8'>
      <div class='bg-gray-100 p-4' v-html='term.text'></div>
      <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-normal'>
        <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
        <span class='px-1'>{{ term.confirmationText }}</span>
      </label>
    </div>
    <div class='mb-6' v-if='showCountryField'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Country</label>
      <div>
        <vue-country-code
          default-country='kr'
          @onSelect='countrySelect'
          v-model='country'
          :preferred-countries="['kr']">
        </vue-country-code>
        {{displayCountry}}
      </div>
    </div>
    <div class='mb-6' v-if='showPasswordFields'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Email</label>
      <input
        v-model='signupParams.email'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='Email'>
      <div class='text-gray-500 text-xs leading-4 mt-2 w-full lg:w-80'>This email address will be your username to login to the virtual site. It will also be used to communicate with you important details about the conference.</div>
    </div>
    <div class='mb-6' v-if='showPasswordFields'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Password</label>
      <input
        v-model='signupParams.password'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='password'
        placeholder='Password'>
    </div>
    <div class='mb-6' v-if='showPasswordFields'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Verify Password</label>
      <input
        v-model='signupParams.passwordConfirmation'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='password'
        placeholder='Confirm Password'>
    </div>
    <form-field-medical-profession
      v-bind:medical-profession.sync='medicalProfession'
      class='mb-6' />
    <form-field-title v-if='!isKorean'
      v-bind:title.sync='title'
      class='mb-6' />
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>First & Middle Name (English)</label>
      <input
        v-model='profile.firstName'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='First Name'
        @input='blockKoreanInput($event)'>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Last Name (English)</label>
      <input
        v-model='profile.lastName'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='Last Name'
        @input='blockKoreanInput($event)'>
    </div>
    <div class='mb-6' v-if='isKorean'>
      <label class='uppercase text-sm text-gray-500 mb-2'>성명 (국문)</label>
      <input
        v-model='profile.name'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        @input='onlyKoreanNameInput($event)'
        placeholder='성명'>
    </div>
    <div class='mb-6' v-if='isKorean'>
      <label class='uppercase text-sm text-gray-500 mb-2'>License Number</label>
      <input
        v-model='profile.licenseNumber'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='License Number'>
    </div>
    <form-field-affiliation
      v-bind:hospitalName.sync='hospitalName'
      v-bind:organization.sync='organization'
      :is-korean='isKorean' />
    <form-field-department
      v-bind:department.sync='department'
      class='mb-6' />
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>City</label>
      <input
        v-model='profile.city'
        class='border py-1 px-2 rounded block w-full lg:w-80'
        type='text'
        placeholder='City'>
    </div>
    <div class='mb-6'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Mobile Phone</label>
      <vue-phone-number-input
        class='block w-full lg:w-80'
        v-model='phoneNumber'
        :default-country-code='defaultCountry'
        :valid-color='eventMainThemeColor'
        @update='formatProfilePhoneNumber' />
      <div class='text-gray-500 text-xs leading-4 mt-2 w-full lg:w-80'>Please ensure you have entered your number correctly. <br>It will be used to communicate with you important details about the conference.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import VueCountryCode from 'vue-country-code'
import VuePhoneNumberInput from 'vue-phone-number-input'
import FormFieldMedicalProfession from '@/components/FormFieldMedicalProfession.vue'
import FormFieldTitle             from '@/components/FormFieldTitle.vue'
import FormFieldDepartment        from '@/components/FormFieldDepartment.vue'
import FormFieldAffiliation       from '@/components/FormFieldAffiliation.vue'
import SignupTermsHelpers         from '@/utils/signup-terms-helpers.js'

export default {
  name: 'SignupFormFields',
  components: {
    VueCountryCode,
    VuePhoneNumberInput,
    FormFieldAffiliation,
    FormFieldDepartment,
    FormFieldMedicalProfession,
    FormFieldTitle,
  },
  props: [
    'showPasswordFields',
    'showCountryField',
  ],
  data () {
    return {
      displayCountry: '',
      country: '',
      invalidPhoneNumber: false,
    }
  },
  watch: {
    'showingEventId': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = SignupTermsHelpers.signupTerms(newVal)
        }
      },
      immediate: true 
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'terms',
      'profile',
      'profile.title',
      'profile.hospitalName',
      'profile.organization',
      'profile.department',
      'profile.phoneNumber',
      'profile.extraInfo.medicalProfession',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor',
    ]),
    isKorean () {
      return !this.profile.country ||
             this.profile.country.toLowerCase() === 'kr'
    },
    defaultCountry () {
      return this.profile.country ? this.profile.country : 'KR'
    },
  },
  methods: {
    countrySelect ({name, iso2}) {
      this.displayCountry = name
      this.profile.country = iso2
    },
    formatProfilePhoneNumber (data) {
      this.phoneNumber = data.formatInternational
      this.$emit('update-phone-number-is-valid', data.isValid)
    },
    blockKoreanInput (event)  {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
    onlyKoreanNameInput(event) {
      this.profile.name = event.target.value.replace(/[^\u3131-\u3163 ^\uac00-\ud7a3]/g, '')
    },
  },
}
</script>