<template>
  <div class='flex flex-col items-stretch lg:flex-row min-h-screen'>
    <div
      class='w-full lg:w-80 px-4 lg:px-16 py-16 text-gray-50 flex flex-col items-center justify-center'
      :style='backgroundStyle'>
      <h1 class='text-3xl font-light'>Signup</h1>
    </div>
    <div class='p-8 lg:p-12 overflow-y-auto h-screen flex-grow'>
      <div class='max-w-2xl'>
        <signup-form-fields
          :show-password-fields='true'
          :show-country-field='true'
          :show-medical-professional-fields='false'
          @update-phone-number-is-valid='updatePhoneNumberIsValid' />
        <ul class='mt-8 mb-6 list-disc list-inside text-red-600 text-xs text-left'>
          <li v-if='notAgreed'>Please agree to terms</li>
          <li v-if='emptyEmailField'>Please enter your email</li>
          <li v-if='!validEmail'>Verify email format</li>
          <li v-if='emptyPasswordField'>Please enter your password</li>
          <li v-if='emptyCountry'>Please select a country</li>
          <li v-if='emptyTitle'>Please select a title</li>
          <li v-if='emptyFirstName'>Please enter your first name</li>
          <li v-if='emptyLastName'>Please enter your last name</li>
          <li v-if='emptyAffiliation'>Please enter your affiliation</li>
          <li v-if='emptyMobile'>Please enter your mobile phone number with international calling code</li>
          <li v-if='invalidPhoneNumber'>Please check your mobile phone number</li>
        </ul>
        <button
          :disabled='disabledSignupButton'
          @click='submitSignup'
          class='border py-2 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'
          :class='disabledSignupButtonClass'>
          Signup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SignupFormFields from '@/components/SignupFormFields.vue'

export default {
  name: 'Signup',
  data () {
    return {
      invalidPhoneNumber: false,
    }
  },
  components: {
    SignupFormFields,
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
      'signupParams',
      'profile',
      'terms',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    emptyEmailField () {
      return !this.signupParams.email
    },
    emptyPasswordField () {
      return !this.signupParams.password || !this.signupParams.passwordConfirmation
    },
    emptyCountry () {
      return !this.profile.country
    },
    emptyTitle () {
      return !this.isKorean && !this.profile.title
    },
    emptyFirstName () {
      return !this.profile.firstName
    },
    emptyLastName () {
      return !this.profile.lastName
    },
    emptyAffiliation () {
      return !this.profile.organization
    },
    emptyDepartment () {
      return !this.profile.department
    },
    emptyAddress () {
      return !this.profile.address
    },
    emptyCity () {
      return !this.profile.city
    },
    emptyPostal () {
      return !this.profile.postalCode
    },
    emptyMobile () {
      return !this.profile.phoneNumber || this.profile.phoneNumber.length < 6
    },
    isKorean () {
      return !this.profile.country ||
             this.profile.country.toLowerCase() === 'kr'
    },
    notAgreed () {
      return !this.terms.filter(term => term.required).every(term => term.agreed)
    },
    notListedTitle () {
      return this.selectedTitle === 'Other'
    },
    validEmail () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return mailformat.test(this.signupParams.email)
    },
    disabledSignupButton () {
      return this.notAgreed ||
             this.emptyEmailField || !this.validEmail ||
             this.emptyPasswordField ||
             this.emptyCountry ||
             this.emptyTitle || 
             this.emptyFirstName || this.emptyLastName ||
             this.emptyAffiliation ||
             this.emptyMobile || this.invalidPhoneNumber
    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50' : ''
    }
  },
  methods: {
    ...mapActions('users', [
      'signup',
      'createProfile',
      'patchTerms'
    ]),
    ...mapActions('users', [
      'checkTokenStatus'
    ]),
    redirectAfterAction () {
      let redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      this.$router.push({name: redirectRoute})
    },
    updatePhoneNumberIsValid (isValid) {
     this.invalidPhoneNumber = !isValid
    },
    submitSignup () {
      if (this.hasValidToken) {
        this.patchTerms()
        this.createProfile() // will update if exists
      } else {
        this.signup().then(() => {
          this.patchTerms()
          this.createProfile()
          this.redirectAfterAction()
        }).catch((error) => {
          if (error.response.status === 409) {
            this.$alert('이미 등록된 이메일입니다. 이메일을 확인 후 다시 등록해주세요.', {
              confirmButtonText: '확인',
              type: 'warning'
            })   
          } else {
            console.error(error)
          }
        })
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.redirectAfterAction()
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
