class SignupTermsHelpers {

  static signupTerms (eventId) {
    if (eventId === 9) {
      // koa2022
      return [
        {
          text: `<strong>Purpose</strong><br>
            The Korean Orthopaedic Association provides pre-registration service online for Korean Orthopaedic Association(abbreviated to KOA2022 hereafter). Based on your personal information, you will be able to sign up for the conference and to complete the payment of your registration fee.
            <br>
            <br>
            <strong>Collecting your personal information</strong><br>
            The Korean Orthopaedic Association requires you to provide your personal information in order to complete pre-registration online.
            You will be asked to enter your name, ID (E-mail), password, title, position, and department.
            <br>
            <br>
            <strong>Storing of your personal information</strong><br>
            The Korean Orthopaedic Association will keep storing of your personal information to provide you with The Korean Orthopaedic Association’s useful services such as conference updates and newsletters.`,
          required: true,
          agreed: false,
          confirmationText: 'I agree to the collection and use of my personal information.',
        }
      ]
    } else if (eventId === 18) {
      // koa-mm
      return [
        {
          text: `<strong>1. 개인정보를 제공받는 자</strong><br>
            대한정형외과학회
            <br>
            <br>
            <strong>2. 개인정보 이용 목적</strong><br>
            학회 사전등록 신청 사실 확인 및 운영, 관리
            <br>
            <br>
            <strong>3. 제공하는 개인정보 항목</strong><br>
            회원구분, 이름, 면허번호, 생년월일, 핸드폰번호, E-mail 주소, 근무처명, 주소, 등록비, 입금자명
            <br>
            <br>
            <strong>4. 개인정보 보유 및 이용기간</strong><br>
            온라인 학술대회 종료일로부터 4개월까지개인정보의 수집 및 이용을 거부할 수 있으며, 동의한 이후에도 언제든지 이를 철회할 수 있습니다. 다만, 개인정보의 수집 및 이용을 거부하는 경우 사전등록을 진행하실 수 없습니다.`,
          required: true,
          agreed: false,
          confirmationText: '필수) 개인정보 수집 및 제3자 제공에 동의하고 신청하시겠습니까?',
        }
      ]
    } else if (eventId === 19) {
      // kiha
      return [
        {
          text: `<strong>[개인정보의 제공 및 위탁]</strong>
            <br>
            <br>
            한국산업보건학회는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
            <br>
            <br>
            <ul style="list-style-type: disc; list-style-position: inside;">
              <li>개인정보의 수집 및 이용목적: 교육서비스 제공</li>
              <li>수집하는 개인정보 항목: 개인등록정보</li>
              <li>개인정보의 보유 및 이용기간: 2023년 8월 31일까지 보유<br>
              <span style="margin-left: 0.5rem;">- 정보제공자가 개인정보 수집, 이용에 대한 동의를 철회할 경우 개인정보 즉시 삭제</span>
              </li>
              <li>개인정보 제공 동의 거부 권리 및 동의 거부 따른 불이익 또는 제한: 개인정보 제공 동의를 거부할 권리가 있으며, 동의 거부에 따른 불이익은 없음. <span style="text-decoration: underline;"><strong>다만, 교육등록을 할 수 없음</strong></span></li>
            </ul>
            <br>
            <br>
            수탁기관명: 플랜베어, 레메디컴퍼니<br>
            위탁업무내용: 온라인 컨벤션 서비스 제공<br>
            위탁기관: 2023년 8월 31일까지
            <br>
            <br>
            한국산업보건학회는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적ㆍ관리적 보호조치, 재위탁제한, 수탁자에 대한 관리ㆍ감독, 손해배상 등 책임에 관한 사항과, 수탁자가 개인정보를 안전하게 관리하는지를 감독하고 있습니다.`,
          required: true,
          agreed: false,
          confirmationText: '동의합니다.',
        }
      ]
    } else if (eventId === 20) {
      // jeilpharm
      return [
        {
          text: `<strong>개인정보의 수집 및 이용에 대한 동의</strong><br><br>
          제일약품㈜(이하"회사")은 귀하로부터 [성함, 병원명, 연락처 등] 개인정보를 수집합니다.<br><br>
          위 정보는 회사가 주최하는 “REVOLUTION web symposium" (이하 "행사") 의 진행 및 관련 활동의 수행을 위하여 수집 및 이용 됩니다.<br><br>
          회사는 이와 같은 수집 및 이용 목적을 달성할 때까지 귀하의 개인 정보를 보유 및 이용 합니다. 귀하는 위와 같은 개인 정보의 수집 및 이용을 거부할 수 있습니다.<br><br>
          다만 본 개인정보의 수집 및 이용에 동의하지 않으실 경우 본 행사 참석에 어려움이 발생 할 수 있음을 알려 드립니다. 또한 회사는 행사의 원활한 진행을 위하여 [제일약품㈜]에 위에 열거한 귀하의 개인정보를 제공할 수 있으며 [제일약품㈜] 은 행사 진행을 위한 개인 정보를 이용 목적을 달성할때까지 해당 정보를 보유 및 이용 합니다.`,
          required: true,
          agreed: false,
          confirmationText: '본인은 개인정보의 수집 및 이용과 제3자 제공에 대하여 동의합니다.',
        },
        {
          text: `<strong>본인은 보건의료전문가임을 확인합니다.</strong>`,
          required: true,
          agreed: false,
          confirmationText: '보건의료전문가임을 확인합니다.',
        }
      ]
    } else {
      return []
    }
  }
}

export default SignupTermsHelpers
